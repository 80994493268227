import * as React from "react";
import { Box } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";
import BrandedPinSvg from "../../../public/branded-pin.svg";
import Link from "next/link";

interface BrandedPinProps {
  lat?: number;
  lng?: number;
  href?: string;
}

export const BrandedPin = (props: BrandedPinProps & BoxProps) => {
  if (props.href) {
    return (
      <Box {...props} transform="translate(-20px, -45px)" zIndex={2132}>
        <Link href={props.href}>
          <BrandedPinSvg />
        </Link>
      </Box>
    );
  }
  return (
    <Box {...props} transform="translate(-20px, -45px)">
      <BrandedPinSvg />
    </Box>
  );
};
