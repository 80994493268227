import { APARTMENT_TYPE_FRAGMENT, ApartmentType } from "@/components/ApartmentType";
import { ApartmentCardFragment } from "@/generated/graphql";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { DEFAULT_CONTENTFUL_IMAGES_QUALITY } from "@/utils/assetsToImages";
// import { toKebabCase } from "@/utils/toKebabCase";
import { BillsIncluded } from "@/components/BillsIncluded";
import { getApartmentImages } from "@/utils/getApartmentImages";
import { getAvailabilityText } from "@/utils/getAvailabilityText";
import type { LinkBoxProps } from "@chakra-ui/react";
import { Badge, Box, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import useTranslation from "next-translate/useTranslation";
import NextLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { Carousel } from "../Carousel";
import { Image } from "../common";
import { Price } from "../Price";

export const APARTMENT_CARD_FRAGMENT = gql`
  fragment ApartmentCard on ListingType {
    availableFrom
    availableRooms
    available
    citySlug
    currency
    apartmentName
    apartmentSuburb
    apartmentSlug
    apartmentTypeName
    totalRooms
    minPrice
    startingPrice
    unitType
    ...ApartmentType
    apartmentCmsData
    apartmentMedia
    buildingMedia
  }
  ${APARTMENT_TYPE_FRAGMENT}
`;

export interface ApartmentCardProps extends LinkBoxProps {
  apartment: ApartmentCardFragment;
  orientation?: "vertical" | "horizontal";
  withBadge?: boolean;
  imageSizes?: string | { [key: string]: string };
}

export const ApartmentCard = React.memo(function ApartmentCard({
  apartment,
  withBadge,
  orientation = "vertical",
  onMouseEnter,
  onMouseLeave,
  imageSizes,
  ...rest
}: ApartmentCardProps) {
  const height = { base: "230px", md: "224px", xl: "204px" };
  const width = "100%";
  const { t } = useTranslation("common");
  const { locale } = useRouter();

  const images = getApartmentImages({ apartment, width: 720 });

  const handleClick = () => {
    if (withBadge) {
      logEvent(AMPLITUDE_EVENTS.apartmentClick, {
        apartmentName: apartment.apartmentName,
        available: apartment.available,
      });
    }
  };

  const availabilityText = getAvailabilityText({
    available: apartment.available,
    availableFrom: apartment.availableFrom,
    t,
    locale,
  });

  const threeMonthsFromNow = new Date(new Date().setMonth(new Date().getMonth() + 3));
  const isAvailableWithinThreeMonths =
    apartment.availableFrom && new Date(apartment.availableFrom) < threeMonthsFromNow;
  const isAvailableNow = apartment.availableFrom && new Date(apartment.availableFrom) < new Date();
  const isAvailableInFuture = apartment.availableFrom && new Date(apartment.availableFrom) > new Date();

  return (
    <LinkBox
      position="relative"
      display="flex"
      flexDirection={{ base: "column", md: orientation === "vertical" ? "column" : "row" }}
      onClick={handleClick}
      isolation="isolate"
      _hover={{
        ".carousel-button": {
          opacity: 0.9,
        },
      }}
      onMouseEnter={(event) => {
        if (onMouseEnter) {
          onMouseEnter(event);
        }
      }}
      onMouseLeave={(event) => {
        if (onMouseLeave) {
          onMouseLeave(event);
        }
      }}
      {...rest}
    >
      {images && (
        <Carousel
          images={images}
          height={height}
          width={{ base: width, md: orientation === "vertical" ? width : "300px" }}
          imageSizes={imageSizes}
          zIndex={{ base: 2, md: "unset" }}
        />
      )}
      {apartment.apartmentCmsData?.heroImage?.file?.url && !images && (
        <Image
          src={`https:${apartment.apartmentCmsData?.heroImage.file.url + DEFAULT_CONTENTFUL_IMAGES_QUALITY}`}
          height={height}
          width={width}
          sizes={imageSizes}
          alt={t("apartment-alt-image")}
          borderRadius="lg"
          overflow="hidden"
        />
      )}
      <Box
        mt={{ base: 4, md: orientation === "vertical" ? 4 : 0 }}
        ml={{ base: 0, md: orientation === "vertical" ? 0 : 6 }}
      >
        {/* TODO: translations for specification */}
        {apartment.apartmentSuburb ? (
          <Text fontSize="12px" fontWeight="medium" color="gray.900" mb={1} textTransform="uppercase">
            {/* {t(`apartment-suburb.${toKebabCase(apartment.apartmentSuburb)}`)} */}
            {apartment.apartmentSuburb}
          </Text>
        ) : null}
        <NextLink href={`/${apartment.citySlug}/homes/${apartment.apartmentSlug}`} passHref legacyBehavior>
          <LinkOverlay isExternal>
            <Heading as="h4" fontSize="3xl" fontWeight="semibold" mb={2}>
              {apartment.apartmentName}
            </Heading>
          </LinkOverlay>
        </NextLink>
        {apartment.totalRooms ? (
          <Text mb={2}>{t("apartment-total-rooms", { count: apartment.totalRooms })}</Text>
        ) : null}
        {withBadge && apartment.available && isAvailableWithinThreeMonths ? (
          <Box
            display="flex"
            justifyContent={{ base: "flex-start", sm: "space-between", md: "flex-start" }}
            flexDirection={{ base: "column", sm: "row", md: "column" }}
            gap={{ base: 1, xs: 2, md: 1 }}
            mb={3}
          >
            <Text fontSize="md" color="gray.600">
              <Price
                apartmentSlug={apartment.apartmentSlug}
                apartmentType={apartment.apartmentTypeName}
                citySlug={apartment.citySlug}
                currency={apartment.currency}
                startingPrice={apartment.startingPrice ?? apartment.minPrice ?? 0}
                i18nKey={
                  apartment.unitType === "shared" ? "common:individual-rooms-from" : "common:entire-apartment-from"
                }
                includeFootnote
              />
            </Text>
            <BillsIncluded apartment={apartment} />
          </Box>
        ) : null}
        {apartment.apartmentTypeName ? (
          <ApartmentType
            position="relative"
            zIndex={1}
            name={apartment.apartmentTypeName}
            description={apartment.apartmentTypeDescription}
          />
        ) : null}
      </Box>
      {withBadge && (
        <Badge
          position="absolute"
          top={2}
          left={2}
          variant={isAvailableNow ? "success" : isAvailableInFuture ? "warning" : "error"}
          zIndex={2}
          data-available-from={apartment.availableFrom}
          data-available={apartment.available}
        >
          {availabilityText}
        </Badge>
      )}
    </LinkBox>
  );
});
